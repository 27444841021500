import { cva } from '@mentimeter/ragnar-tailwind-config';

const button = cva(
  'disabled:bg-disabled-strong disabled:text-on-disabled-strong disabled:shadow-none disabled:cursor-not-allowed flex flex-row justify-center items-center text-center relative no-underline font-body font-semibold cursor-pointer rounded-full whitespace-nowrap opacity-100 duration-300 ease-in-out focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-2 focus-visible:outline-interactive-focused',
  {
    variants: {
      size: {
        large: 'px-8 py-4 text-100 leading-125',
        default: 'px-6 py-3 text-87.5 leading-100',
        compact: 'px-4 py-2 text-87.5 leading-100',
      },
      variant: {
        primary:
          'bg-button-primary text-on-primary hover:bg-button-primary-hover active:bg-button-primary-active',
        secondary:
          'bg-transparent text hover:bg-button-secondary-hover active:bg-button-secondary-active',
        tertiary:
          'bg-button-tertiary text hover:bg-button-tertiary-hover active:bg-button-tertiary-active',
        brand:
          'bg-button-brand text-on-brand hover:bg-button-brand-hover active:bg-button-brand-active',
        outline:
          'text bg-transparent shadow-[inset_0_0px_0px_2px_theme(colors.primary.DEFAULT)] hover:bg-button-outline-hover active:bg-button-outline-active',
        subtle:
          'bg-transparent text border-transparent hover:bg-transparent hover:border-transparent active:bg-transparent active:border-transparent rounded-none py-0.5 px-0.5 disabled:bg-transparent disabled:text-disabled ',
        positive:
          'bg-button-positive text-on-positive hover:bg-button-positive-hover active:bg-button-positive-active',
        negative:
          'bg-button-negative text-on-negative hover:bg-button-negative-hover active:bg-button-negative-active',
        negativeWeak:
          'bg-button-negative-weak text-on-negative-weak hover:bg-button-negative-weak-hover active:bg-button-negative-weak-active ',
      },
    },
    defaultVariants: {
      variant: 'tertiary',
      size: 'default',
    },
  },
);

export const variants = {
  button,
};
